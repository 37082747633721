<!-- sidebar menu -->
<div #sidebarmenu id="sidebar-menu" class="main_menu_side hidden-print main_menu">
    <div class="menu_section">
      <!-- <h3>General</h3> -->
      <ul class="nav side-menu">
        <li id="master" routerLinkActive="active" [ngClass]="{'active':isopenmenu['master']}" ><a (click)="openmenu('master');anchorClicked($event,'master');"><i class="fa fa-home"></i> Master Data <span class="fa fa-chevron-down" ></span></a>
          <ul class="nav child_menu"  >
            <li routerLinkActive="current-page"><a routerLink="/master/vendortype" >Vendor Type</a></li>
            <li routerLinkActive="current-page"><a routerLink="/master/vendor"  >Vendor</a></li>
          </ul>
        </li>
        <!-- <li routerLinkActive="active"><a routerLink="/erf" (click)="clear()"><i class="fa fa-edit"></i> Employee Request Form</a> -->
          <!-- <ul class="nav child_menu">
            <li><a href="form.html">General Form</a></li>
            <li><a href="form_advanced.html">Advanced Components</a></li>
            <li><a href="form_validation.html">Form Validation</a></li>
            <li><a href="form_wizards.html">Form Wizard</a></li>
            <li><a href="form_upload.html">Form Upload</a></li>
            <li><a href="form_buttons.html">Form Buttons</a></li>
          </ul> -->
        <!-- </li> -->
        <li routerLinkActive="active"><a routerLink="/pr" (click)="clear()"><i class="fa fa-desktop"></i>Purchase Request</a>
          <!-- <ul class="nav child_menu">
            <li><a href="general_elements.html">General Elements</a></li>
            <li><a href="media_gallery.html">Media Gallery</a></li>
            <li><a href="typography.html">Typography</a></li>
            <li><a href="icons.html">Icons</a></li>
            <li><a href="glyphicons.html">Glyphicons</a></li>
            <li><a href="widgets.html">Widgets</a></li>
            <li><a href="invoice.html">Invoice</a></li>
            <li><a href="inbox.html">Inbox</a></li>
            <li><a href="calendar.html">Calendar</a></li>
          </ul> -->
        </li>
        <li routerLinkActive="active"><a routerLink="/cqr" (click)="clear()"><i class="fa fa-table"></i> Competitive Quotation Record </a>
          <!-- <ul class="nav child_menu">
            <li><a href="tables.html">Tables</a></li>
            <li><a href="tables_dynamic.html">Table Dynamic</a></li>
          </ul> -->
        </li>
        <li routerLinkActive="active"><a routerLink="/po" (click)="clear()"><i class="fa fa-bar-chart-o"></i> Purchase Order </a>
          <!-- <ul class="nav child_menu">
            <li><a href="chartjs.html">Chart JS</a></li>
            <li><a href="chartjs2.html">Chart JS2</a></li>
            <li><a href="morisjs.html">Moris JS</a></li>
            <li><a href="echarts.html">ECharts</a></li>
            <li><a href="other_charts.html">Other Charts</a></li>
          </ul> -->
        </li>

        <li routerLinkActive="active"><a routerLink="/rolepage" (click)="clear()"><i class="fa fa-list-alt"></i> Role Template </a>
          <!-- <ul class="nav child_menu">
            <li><a href="chartjs.html">Chart JS</a></li>
            <li><a href="chartjs2.html">Chart JS2</a></li>
            <li><a href="morisjs.html">Moris JS</a></li>
            <li><a href="echarts.html">ECharts</a></li>
            <li><a href="other_charts.html">Other Charts</a></li>
          </ul> -->
        </li>

        <!-- <li routerLinkActive="active"><a routerLink="/goodreceive" (click)="clear()"><i class="fa fa-clone"></i>Good Receive </a> -->
          <!-- <ul class="nav child_menu">
            <li><a href="fixed_sidebar.html">Fixed Sidebar</a></li>
            <li><a href="fixed_footer.html">Fixed Footer</a></li>
          </ul> -->
        <!-- </li> -->
        <!-- <li routerLinkActive="active"><a routerLink="/goodreturn" (click)="clear()"><i class="fa fa-clone"></i>Good Return </a> -->
            <!-- <ul class="nav child_menu">
              <li><a href="fixed_sidebar.html">Fixed Sidebar</a></li>
              <li><a href="fixed_footer.html">Fixed Footer</a></li>
            </ul> -->
          <!-- </li> -->
      </ul>
    </div>
   
  </div>
  <!-- /sidebar menu -->